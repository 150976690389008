













































import { Component, Inject as VueInject, Mixins, Prop } from 'vue-property-decorator'

import {
  defaultProvider,
  Inject,
  IS_MOBILE_PROVIDER_KEY
} from '../../../../support'
import { FormErrorsMixin, StructureConfigurable } from '../../../../support/mixins'

import { Form } from '../../../shared/molecules/Form'
import { ToastMixin } from '../../../shared/mixins/toast.mixin'

import { AuthControlServiceType, IAuthControl } from '../../contracts'

import { RequestResetLinkFormData } from './RequestResetLinkForm.contracts'
import { requestResetLinkValidationsMap } from './RequestResetLinkForm.helpers'
import {
  defaultComponentConfig,
  REQUEST_RESET_LINK_FORM_COMPONENT_KEY
} from './RequestResetLinkForm.config'

/**
 * @author Katarzyna Otto <katarzyna.otto@movecloser.pl>
 * @author Wojciech Falkowski <wojciech.falkowski@movecloser.pl> (edited)
 */
@Component<RequestResetLinkForm>({
  name: 'RequestResetLinkForm',
  components: { Form },
  created () {
    this.config = this.getComponentConfig(REQUEST_RESET_LINK_FORM_COMPONENT_KEY, defaultComponentConfig)
  }
})
export class RequestResetLinkForm extends Mixins(FormErrorsMixin, ToastMixin, StructureConfigurable) {
  @VueInject({ from: IS_MOBILE_PROVIDER_KEY, default: () => defaultProvider<boolean>(false) })
  public readonly isMobile!: () => boolean

  @Inject(AuthControlServiceType)
  protected readonly requestResetLinkFormRepository!: IAuthControl

  @Prop({ required: false, type: Boolean, default: false })
  public isSecondary!: boolean

  public isSend: boolean = false

  /**
   * @override
   *
   * @see AbstractForm.validatorsMap
   */
  protected validatorsMap = requestResetLinkValidationsMap

  /**
   * Default values for formData.
   */
  public formData: RequestResetLinkFormData = {
    email: ''
  }

  public get backIcon (): string {
    return this.getConfigProperty<string>('backIcon')
  }

  /**
   * Sets props for submit button.
   */
  public get submitProps () {
    return {
      theme: this.isSecondary ? 'secondary' : 'primary',
      isFluid: this.isSecondary || this.isMobile()
    }
  }

  public async resetLink (): Promise<void | boolean> {
    this.error = null

    return this.requestResetLinkFormRepository.requestResetLink(this.formData)
  }

  /**
   * Handles @success event of `Form`
   */
  public onSuccess (): void {
    this.showToast(this.$t('front.auth.organisms.requestResetLinkForm.success')
      .toString(), 'success')
    this.$emit('onSuccess')
    this.isSend = true
  }

  /**
   * Handles @fail event of `Form`
   */
  public onFail (error: any): void {
    this.setError(error)
  }
}

export default RequestResetLinkForm

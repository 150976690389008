


























































































import { Component, Mixins, Watch } from 'vue-property-decorator'

import { AddressData } from '../../../../../contexts'
import { Inject, logger } from '../../../../../support'

import {
  authSSOParamsKey,
  authSSOSelectedProvider,
  authSSOSessionId
} from '../../../../auth/config/auth'
import { AuthControlServiceType, IAuthControl } from '../../../../auth/contracts'
import { LoginForm } from '../../../../auth/organisms/LoginForm'
import { AuthMixin, SSOAuthMixin } from '../../../../auth/shared'
import { RouteNames as AuthRouteNames } from '../../../../auth/routes'
import { UserMixin } from '../../../../profile/shared'

import { CartMutationTypes, CheckoutPayload } from '../../../contracts'
import {
  GuestDetailsFormFieldsetTwo
} from '../../../molecules/GuestDetailsFormFieldset/GuestDetailsFormFieldsetTwo.vue'
import GuestDetailsFormFieldsetSSO
  from '../../../molecules/GuestDetailsFormFieldset/GuestDetailsFormFieldsetSSO.vue'

import { LoginAndRegister } from '../../BuyerContextSwitch/versions/LoginAndRegister.vue'
import { CustomerDetailsStep } from '../CustomerDetailsStep.vue'
import { AddressSelector } from '../../AddressSelector'
import { translateCheckoutPayloadToValidationObject } from '../../../helpers/checkout.helpers'
import FormErrorsMixin from '../../../../../support/mixins/FormErrors.mixin.vue'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
@Component<CustomerDetailsStepSimplified>({
  name: 'CustomerDetailsStepSimplified',
  components: {
    AddressSelector,
    BuyerContextLoginAndRegister: LoginAndRegister,
    GuestDetailsFormFieldsetTwo,
    GuestDetailsFormFieldsetSSO,
    LoginForm
  },
  mounted (): void {
    // Save the first payload.
    this.checkoutPayload = this.payload
  }
})
export class CustomerDetailsStepSimplified extends Mixins(CustomerDetailsStep, AuthMixin, FormErrorsMixin, UserMixin, SSOAuthMixin) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  public addressMode: 'edit' | 'new' | 'preview' = 'preview'
  public mode: 'login' | 'register' = 'login'

  public selectedUserAddressId: number | null = null
  public shouldObserve: boolean = false

  protected checkoutPayload: CheckoutPayload | null = null

  public get guestDetailsData () {
    return {
      address: this.payload.address,
      user: this.payload.user,
      acceptContent: this.payload.acceptContent
    }
  }

  public set guestDetailsData (values: any) {
    for (const [key, value] of Object.entries(values)) {
      this.onChange(key, value)
    }
  }

  /**
   * Information whether user has been authenticated using SSO
   */
  public get ssoAuthenticated (): boolean {
    return this.$store.getters['checkout/ssoAuthenticated']
  }

  /**
   * Information whether user must accept consents to finish SSO authentication
   */
  public get ssoConsentsRequested (): boolean {
    return this.$store.getters['checkout/ssoConsentsRequest']
  }

  public get withSocials (): boolean {
    return this.getConfigProperty<boolean>('withSocials')
  }

  /**
   * Switch addressMode to passed-in value.
   */
  public addressModeTo (mode: 'new' | 'edit' | 'preview'): void {
    this.addressMode = mode
  }

  /**
   * Handles the @edit event of `AddressSelector` component.
   */
  public async onAddressEdit (payload: { id: number; address: AddressData }): Promise<void> {
    this.mapUserAddressToCheckoutPayload(payload.address)

    this.addressMode = 'edit'
    this.selectedUserAddressId = payload.id
  }

  /**
   * Handles the @onMounted event of `AddressSelector` component.
   */
  public onAddressMounted (payload: { id: number; address: AddressData }): void {
    this.mapUserAddressToCheckoutPayload(payload.address)
  }

  /**
   * Handles @onAddress event of `AddressSelector` component.
   */
  public async onAddressSelection (payload: { id: number; address: AddressData } | undefined): Promise<void> {
    if (!payload) {
      return
    }

    this.$emit('saving', true)

    this.mapUserAddressToCheckoutPayload(payload.address)

    try {
      await this.profileService.updateDeliveryAddress(payload.id, {
        ...payload.address,
        defaultBilling: true
      })

      if (!this.authService.token) {
        return
      }

      const user = await this.profileService.getUserDetails(this.authService.token.accessToken)
      this.authService.setUser(user)
      this.$store.commit('profile/SET_USER', user)
    } catch (e) {
      logger(e, 'error')
    } finally {
      this.$emit('saving', false)
    }
  }

  /**
   * Handles the cancellation of editing of selected address.
   */
  public onEditCancel (): void {
    this.addressMode = 'preview'
    this.errors = null
    if (this.checkoutPayload) {
      this.onChange('user', this.checkoutPayload.user)
      this.onChange('address', this.checkoutPayload.address)
    }
  }

  /**
   * Handle forgot password event.
   */
  public onForgotPassword (): void {
    this.$router.push({
      name: `auth.${AuthRouteNames.RequestResetPassword}`
    })
  }

  /**
   * Handles update of user's selected address.
   */
  public async onEditAddress (): Promise<void> {
    try {
      if (!this.selectedUserAddressId) {
        return
      }

      await this.onUserAddress(this.selectedUserAddressId, false)
    } catch (e) {
      logger((e as Error).message, 'error')
    } finally {
      this.$emit('saving', false)
    }
  }

  /**
   * Handles successful login
   */
  public onSuccess (): void {
    this.shouldObserve = true
  }

  public updateGuestModel (key: string, value: unknown) {
    this.guestDetailsData = {
      ...this.guestDetailsData,
      [key]: value
    }
  }

  @Watch('user')
  protected onUserChange () {
    if (this.shouldObserve) {
      this.eventBus.emit('app:authorization.login', this.user)
    }
  }

  /**
   * Adds / updates new address to user
   */
  protected async onUserAddress (id?: number, isNew: boolean = true): Promise<void> {
    const userAddress = {
      firstName: this.guestDetailsData.user.firstName,
      lastName: this.guestDetailsData.user.lastName,
      street: this.guestDetailsData.address.address,
      countryCode: this.guestDetailsData.address.country,
      phoneNumber: this.guestDetailsData.address.phone,
      postalCode: this.guestDetailsData.address.postalCode,
      city: this.guestDetailsData.address.city,
      company: this.guestDetailsData.address.company,
      vatId: this.guestDetailsData.address.invoice?.nip,
      defaultBilling: true,
      defaultShipping: false
    }

    if (
      !CustomerDetailsStep.isValidStep(
        translateCheckoutPayloadToValidationObject(this.payload),
        this.$t.bind(this),
        this.$i18n.locale,
        this.setErrors
      )
    ) {
      return
    }

    this.$emit('saving', true)

    try {
      if (this.ssoConsentsRequested && !this.ssoAuthenticated) {
        const ssoProvider = localStorage.getItem(authSSOSelectedProvider)
        const ssoParams = localStorage.getItem(authSSOParamsKey)
        const ssoSessionId = localStorage.getItem(authSSOSessionId)

        if (!ssoParams || !ssoProvider || !ssoSessionId) {
          return
        }

        const response = await this.authControl.setSocialCallback(
          JSON.parse(ssoParams),
          ssoProvider,
          JSON.parse(ssoSessionId).id,
          true
        )

        if (response && response.token) {
          await this.authService.setToken(response.token.toAuthToken())
          this.$store.commit(CartMutationTypes.SetSSOAuthenticated, true)
          this.$store.commit(CartMutationTypes.SetSSOConsentsRequest, false)
        }
      }

      if (isNew) {
        await this.profileService.storeDeliveryAddress(userAddress)
      } else {
        if (id) {
          await this.profileService.updateDeliveryAddress(id, userAddress)
        }
      }
    } catch (e) {
      this.$emit('error', (e as Error).message)
      this.$emit('saving', false)
    }

    if (!this.authService.token) {
      return
    }

    try {
      await this.profileService.getUserDetails(this.authService.token.accessToken).then((user) => {
        this.authService.setUser(user)
        this.$store.commit('profile/SET_USER', user)
      })

      this.addressMode = 'preview'
    } catch (e) {
      this.$emit('error', (e as Error).message)
    } finally {
      this.$emit('saving', false)
      if (this.ssoConsentsRequested && !this.ssoAuthenticated) {
        this.nextStep()
      }
    }
  }

  /**
   * Translates & updates user address for checkout payload
   */
  protected mapUserAddressToCheckoutPayload (payload: AddressData): void {
    const { street, company, city, postalCode, vatId, phoneNumber, countryCode } = payload
    const { lastName, firstName } = payload

    this.updateValues({
      address: {
        address: street,
        billingAddress: {
          address: street,
          postalCode,
          city
        },
        country: countryCode,
        company: !company ? '' : company,
        city,
        invoice: {
          company: !company ? '' : company,
          nip: vatId
        },
        postalCode,
        phone: phoneNumber
      },
      user: {
        email: this.payload.user.email,
        firstName,
        lastName
      }
    })
  }

  @Watch('mode')
  protected onModeChange (): void {
    this.$emit('error', null)
  }
}

export default CustomerDetailsStepSimplified

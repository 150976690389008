






























































































import { Component, Prop, PropSync } from 'vue-property-decorator'
import set from 'lodash/set'

import { authSSOCustomerData } from '../../../auth/config/auth'

import { CheckoutPayload } from '../../contracts'

import { GuestDetailsFormFieldset } from './GuestDetailsFormFieldset.vue'

/**
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component<GuestDetailsFormFieldsetSSO>({
  name: 'GuestDetailsFormFieldsetSSO',
  mounted (): void {
    // Set company if present.
    if ((this._formData.address.invoice?.company && this._formData.address.invoice?.company.length > 0) &&
      (this._formData.address.invoice?.nip && this._formData.address.invoice?.nip.length > 0)
    ) {
      this.isCompany = true
    }

    const SSOCustomer = localStorage.getItem(authSSOCustomerData)
    if (SSOCustomer) {
      this._formData = {
        ...this._formData,
        user: {
          ...this._formData.user,
          email: JSON.parse(SSOCustomer).email ?? ''
        }
      }
      this.emailDisabled = true
    }
  }
})
export class GuestDetailsFormFieldsetSSO extends GuestDetailsFormFieldset {
  /**
   * @override
   */
  @PropSync('formData', { type: Object, required: true })
  protected _formData!: CheckoutPayload

  @Prop({ type: Boolean, required: false, default: false })
  public readonly withRegistrationFields!: boolean

  @Prop({ type: Boolean, required: false, default: true })
  public readonly withCompanyCheckboxFields!: boolean

  @Prop({ type: Boolean, required: false, default: false })
  public readonly withConsentFields!: boolean

  public isCompany: boolean = false
  public emailDisabled: boolean = false

  public get company (): string {
    if (!this.isCompany) {
      return this._formData.address.company
    } else {
      return this._formData.address.invoice?.company ?? ''
    }
  }

  public set company (val: string) {
    this.updateModel('address.company', val)

    if (this.isCompany) {
      this.updateModel('address.invoice.company', val)
    }
  }

  /**
   * Updates model (works with nested objects reactivity (reference))
   * @param key - `first.second.third.x`
   * @param value - `value for key`
   */
  public updateModel (key: string, value: unknown): void {
    const newFormData = { ...this._formData }

    set(newFormData, key, value)

    this._formData = newFormData

    this.$emit('update:errors')
  }
}

export default GuestDetailsFormFieldsetSSO

import { AnyObject } from '@movecloser/front-core'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const BUYER_CONTEXT_SWITCH_COMPONENT_KEY = 'BuyerContextSwitchComponent'

/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 */
export const defaultConfig: AnyObject = {
  acceptPrivacyForLoggedInUser: false
}

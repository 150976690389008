


































































import { Component, Mixins, Prop, PropSync } from 'vue-property-decorator'
import { Authentication, AuthServiceType } from '@movecloser/front-core'

import { AuthControlServiceType, IAuthControl } from '../../../../auth/contracts'
import { SSOAuthMixin, UserModel } from '../../../../auth/shared'
import { Inject } from '../../../../../support/plugins/front-core'

import { BuyerContextSwitch } from '../BuyerContextSwitch.vue'
import { authSSOSelectedProvider, AuthSSOSource, authSSOSource } from '../../../../auth/config/auth'
import { logger } from '../../../../../support'
/**
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl>
 * @author Filip Rurak <filip.rurak@movecloser.pl>
 */
@Component({ name: 'LoginAndRegister' })
export class LoginAndRegister extends Mixins(BuyerContextSwitch, SSOAuthMixin) {
  @Inject(AuthControlServiceType)
  protected readonly authControl!: IAuthControl

  @Inject(AuthServiceType)
  protected readonly authService!: Authentication<UserModel>

  @Prop({ type: Boolean, required: false, default: true })
  public readonly withSocials!: boolean

  @PropSync('mode', { type: String, required: true })
  public readonly _mode!: string

  public socialPending: boolean = false

  /**
   * Determines a state when there is no SSO authentication process
   */
  public get noSSOAuthentication (): boolean {
    const ssoAuthenticated = this.$store.getters['checkout/ssoAuthenticated']
    const ssoConsentsRequest = this.$store.getters['checkout/ssoConsentsRequest']
    return !ssoAuthenticated && !ssoConsentsRequest
  }

  /**
   * Callback to sign with socials.
   */
  public async socialLogin (social: string): Promise<void> {
    try {
      this.socialPending = true

      /** Stores selected social provider */
      localStorage.setItem(authSSOSelectedProvider, social)
      /** Stores selected source of authentication */
      localStorage.setItem(authSSOSource, AuthSSOSource.Cart)

      const redirectUri = await this.authControl.getSocialAuthRedirectUri(social)

      if (redirectUri) {
        window.location.href = redirectUri
      }
    } catch (error) {
      logger(error, 'warn')
    } finally {
      this.socialPending = false
    }
  }
}
export default LoginAndRegister


































































import { Component, Mixins, PropSync, Watch } from 'vue-property-decorator'

import { StructureConfigurable } from '../../../../support/mixins'

import { AuthMixin, IAuthMixin, ILogoutMixin, LogoutMixin } from '../../../auth/shared'
import { RequestResetLinkForm } from '../../../auth/organisms/RequestResetLinkForm'
import { LoginForm } from '../../../auth/organisms/LoginForm'
import { IUserMixin, UserMixin } from '../../../profile/shared'
import { Loader } from '../../../shared/molecules/Loader/Loader.vue'

import { GuestDetailsFormData } from '../../molecules/GuestDetailsFormFieldset'
import { StepProps } from '../../molecules/Step/Step.contracts'

import { BUYER_CONTEXT_SWITCH_COMPONENT_KEY, defaultConfig } from './BuyerContextSwitch.config'

/**
 * @author Agnieszka Zawadzka <agnieszka.zawadzka@movecloser.pl> (original)
 * @author Javlon Khalimjonov <javlon.khalimjonov@movecloser.pl> (edited)
 */
@Component<BuyerContextSwitch>({
  name: 'BuyerContextSwitch',
  components: { Loader, LoginForm, RequestResetLinkForm },
  created (): void {
    this.config = this.getComponentConfig(BUYER_CONTEXT_SWITCH_COMPONENT_KEY, defaultConfig)
  },
  mounted () {
    this.isLoading = this.isWaitingForAuth
    if (!this.isLoading) {
      this.setUser(this.isLoggedInUser)
    }
  }
})
export class BuyerContextSwitch extends Mixins<IAuthMixin, ILogoutMixin, IUserMixin,
    StructureConfigurable>(AuthMixin, LogoutMixin, UserMixin, StructureConfigurable) {
  /**
   * @see StepProps.payload
   */
  @PropSync('payload', { type: Object, required: false })
  public _payload?: StepProps['payload']

  public isAnonymous: boolean = !this.isLoggedInUser
  public isForgotPassword: boolean = false
  public isLoading: boolean = true

  /**
   * Determines whether privacy should be checked for logged-in user.
   */
  public get acceptPrivacyForLoggedInUser (): boolean {
    return this.getConfigProperty<boolean>('acceptPrivacyForLoggedInUser')
  }

  public async onLogout (): Promise<void> {
    await this.logout()
    await this.$router.push({ path: '/' })
  }

  public onToggleForgotPassword (): void {
    this.isForgotPassword = !this.isForgotPassword
  }

  @Watch('isWaitingForAuth')
  public setLoading () {
    this.isLoading = false
  }

  @Watch('isLoggedInUser')
  public setUser (isUserLoggedIn: boolean) {
    const user: GuestDetailsFormData | null = {
      ...(isUserLoggedIn ? this.user : this._payload?.user)
    }
    this._payload = {
      user: { ...user, ...this._payload?.user },
      ...this._payload,
      isUserLoggedIn,
      acceptContent: isUserLoggedIn && this.acceptPrivacyForLoggedInUser
    }
  }
}

export default BuyerContextSwitch
